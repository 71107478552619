<template>
  <l-map 
    v-if="centro[0] != 39.4746227" 
    ref="map" 
    v-model:zoom="zoom" 
    :maxZoom="maxZoom" 
    :minZoom="minZoom"
    :center="centro"
    @mousedown="onMapMouseDown"
    @mouseup="onMapMouseUp"
    @move="onMapMove"
  >
    <l-tile-layer  
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
      layer-type="base"
      name="OpenStreetMap">
    </l-tile-layer>
    <l-marker v-if="coordenadas" :lat-lng="coordenadas"></l-marker>
  </l-map>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
import L from 'leaflet';
import { PwgsApi } from "../../../../../services/PwgsApi";

export default {
  props: ['id', 'servicio', 'datoscliente', 'cliente'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      centro: [39.4746227, -0.3477796],
      coordenadas: '',
      zoom: 13,
      iconWidth: 25,
      iconHeight: 40,
      maxZoom: 19,
      minZoom: 2,  
      mapMoved: false, // Variable para verificar si el mapa fue movido
    };
  },
  methods: {
    onMapMouseDown() {
      this.mapMoved = false; // Resetea la variable cuando se hace mousedown
    },
    onMapMouseUp(event) {
      if (!this.mapMoved) {
        // Solo si el mapa no se movió, consideramos que fue un clic
        this.confirmar(event);
      }
    },
    onMapMove() {
      this.mapMoved = true; // Si el mapa se mueve, establecemos que se movió
    },
    confirmar(event) {
      console.log('facturallega');
      this.$confirm.require({
        message: '¿Modificar las coordenadas del expediente?',
        header: 'Confirmación',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Aceptar',
        accept: () => {
          this.editarCoordenadas(event)
        },
        reject: () => {
          return;
        }
      });        
    },
    async cargarcoordenadas() {
      const datos = this.cliente;
      console.log('entraclientesmapa', datos);
      if(datos!=''){
        let aux1 = '';
        setTimeout(() => {
          for (let j = 0; j <= datos.asegurado?.coordenadas.length; j++) {
            if (datos.asegurado?.coordenadas[j] == ",") { 
              aux1 = L.latLng(datos.asegurado?.coordenadas.slice(0, j), datos.asegurado?.coordenadas.slice(j + 1));
            }
          }
          console.log('coords', aux1);
          this.coordenadas = aux1;
          this.centro = aux1;
        }, 400);
      }
    },
    async editarCoordenadas(event) {
      const { lat, lng } = event.latlng;
      try {
        const api = new PwgsApi();
        var coordenadas = [lat, lng];
        await api.put('servicios/'+this.id+'/modificar-coordenadas', {coordenadas: lat + ',' + lng});
        this.coordenadas = coordenadas;
        this.$toast.add({ severity: 'success', summary: 'Actualizadas', detail: 'Coordenadas actualizadas correctamente', life: 2000 });
      } catch(e) {
        this.$toast.add({ severity: 'error', summary: 'Error', detail: 'e', life: 2000 });
      }
    },
    refrescarmapa() {
      document.getElementById(this.$props.id).forceUpdate();
    },
  },
  mounted() { 
    this.cargarcoordenadas();
  },
  watch: {
    cliente() {
      this.$nextTick(() => {
        this.cargarcoordenadas();
      });
    },
    id() {
      this.$nextTick(() => {
        this.cargarcoordenadas();
      });
    },
    ampliarmapa() {}
  }
};
</script>

<style>
.map-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
}

#map {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
